.small-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .small-modal-content {
        position: relative;
        height: auto;
        width: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 20px;

        @media screen and (max-width: 1100px) {
            width: 95vw;
            flex-direction: column;
        }

        h2 {
            margin-top: 12px;
            margin-bottom: 8px;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px
        }   

        h4{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        p{
            text-align: center;
        }

        .btn{
            width: 100%;
            // margin-bottom: 8px;
        }

        .close{
            position: absolute;
            top: 18px;
            right: 25px;
            cursor: pointer;
        }
    }

    .verify-modal-content{
        width: 600px !important;

        ul{
            margin-top: 12px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap:5px;

            li{
                color: #6c6a73;
                span{
                    color: var(--primary-color);
                    font-weight: 500;
                    cursor: pointer;
                }
            }
            li::before {
                content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: #6c6a73; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
              }
        }

        .details{
            width: 100%;
            padding: 0 20px;
            margin-bottom: 16px;
            p{
                text-align: left;
            }
        }

    }
}