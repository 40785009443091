.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 110px 3rem 60px 3rem;

    img {
        width: 276px;
    }
}



.azure-container {
    background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%);
    display: flex;
    justify-content: space-between;
    gap: 160px;
    width: 98vw;
    margin: 0 auto;
    border-radius: 20px;
    padding: 100px 5rem;

    @media screen and (max-width:1100px) {
        padding: 100px 1.2rem;
        flex-direction: column;

        .right-container {
            display: block;
        
        }
    }
}


.left-container {
    .solutions-text {
        h2 {
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 12px;

            span {
                background:linear-gradient(227.46deg, #DC4405 14.94%, #FF8D24 85.6%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
            }

        }


        h3{
            font-size: 36px;
            font-weight: 800;
        }

        p {
            font-size: 16px;
            margin-top: 16px;
            line-height: 24px;
            width: 90%;

            span {
                color: var(--primary-color)
            }
        }

    }
}

.right-container {
    img {
        width: 420px;
        height: 428px;
        border-radius: 0;

        @media screen and (max-width:1100px) {
            width: 100%;
        }
    }

}


.azure-section-second-container {
    width: 98vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0px 96px;
    border-radius: 20px;
    background: linear-gradient(0deg, rgba(255, 242, 229, 0.48) 0%, rgba(255, 242, 229, 0.48) 100%), #FFF;
    margin-top: 32px;

    @media screen and (max-width:1100px) {
        padding: 0px 1.2rem;
        
    }


    h3 {
        font-size: 36px;
        line-height: 50px;
        color: var(--primary-color);
        margin-bottom: 20px;
    }

    p{
        line-height: 24px;
        font-size: 20px;
        span{
            color: var(--primary-color);
            font-weight: 600;
        }
    }
}



.azure-section-third-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
    margin-top: 32px;
    padding-bottom: 0;
    @media screen and (max-width:1100px) {
        padding: 0px 1.2rem;
        grid-template-columns: 1fr;      
    }

    .card1,
    .card2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(0deg, rgba(241, 238, 252, 0.48) 0%, rgba(241, 238, 252, 0.48) 100%), #FFF;
        border-radius: 12px;
        padding: 44px 96px;
        height: 100%;

        @media  screen and (max-width:1100px) {
            padding: 44px 1.2rem;           
        }



        h3 {
            font-size: 32px;
            line-height: 50px;
            color: #3F1BD0;
            margin-bottom: 20px;
        }

        .points-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 100%;
        }

        .points {
            display: flex;
            align-items: start;
            gap: 12px;
            position: relative;
            width: 100%;


            img {
                width: 12px;
                height: 12px;
                transform: translateY(5px);
            }

            p {
                font-size: 20px;
                line-height: 24px;

                span {
                    color: #3F1BD0;
                    font-weight: 600
                }
            }
        }

        .points-right{
            margin-left: 8%;
        }
    }

    .card2 {
        background: linear-gradient(0deg, rgba(255, 242, 229, 0.48) 0%, rgba(255, 242, 229, 0.48) 100%), #FFF;

        h3 {
            color: var(--primary-color);
        }

        .points {
            p {
                span {
                    color: var(--primary-color)
                }
            }
        }
    }

}


.azure-images-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 32px;
    // height:448px ;
    @media screen and (max-width:1100px) {
        padding: 0px 1.2rem;
        grid-template-columns: 1fr;      

    }

    article {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
        position: relative;
        transform: translate3d(0, 0, 0);
        border-radius: 20px;
    
        //  overflow: hidden; 
        img {
            width: 100%;
            height: 300px;
            background-size: cover;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    
        .info-container {
            height: 128px;
            padding: 44px 16px;
            background: #000;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            .top-part {
                display: flex;
                align-items: center;
                height: 100%;
                text-align: center;
                justify-content: center;
    
                h4 {
                    font-size: 36px;
                    font-weight: 600;
                    color: #FCFCFC;

                    @media screen and (max-width:1100px) {
                        font-size: 24px;                 
                    }
                }
            }
        }
    }
}

.azure-images-container-second{
    grid-template-columns: 1fr 1fr;
    margin-top: 32px;
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (max-width:1100px) {
        grid-template-columns: 1fr !important;
    }
    article{
        .second {
            background-color: #f5f5f5;
            .top-part{
                h4{
                    color:#28272e;
                }
            }
        }
    }
}


.azure-section-four-container {
    width: 98vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 96px;
    border-radius: 20px;
    background: linear-gradient(0deg, rgba(255, 242, 229, 0.48) 0%, rgba(255, 242, 229, 0.48) 100%), #FFF;
    margin-bottom: 32px;
    margin-top: 32px;

    @media screen and (max-width:1100px) {
        padding: 60px 1.2rem;
        
    }


    h3 {
        font-size: 36px;
        line-height: 50px;
        color: var(--primary-color);
        text-align: center;
    }
}