.container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #1E1E1E;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 2px 0 0 30px;

    &:hover {
        color: #1E1E1E;

        .checkmark {
            border-color: #1E1E1E;
        }
    }
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #6C6A73;
    border-radius: 4px;
}

.container input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border-color: transparent;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
