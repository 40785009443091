.small-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .small-modal-content {
        position: relative;
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;
        border-top-right-radius: 0;

        @media screen and (max-width: 1100px) {
            width: 95vw;
            flex-direction: column;
        }

        .img-container {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;

            @media screen and (max-width: 1100px) {
              align-items: self-start;
            }

            img {
                // width: 100% !important;
                // max-height: 100% !important;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                width: 100%;
                height: auto;
                object-fit: contain;
                float: right;
                margin: auto;
                max-height: 500px;

                @media screen and (max-width: 1100px) {
                    object-fit: contain;
                    border-bottom-left-radius: 20px;
                    border-top-left-radius: 20px;
                }
            }

            div{
            // height: 100%;
            width: 100%;
            }
        }

        .methods-text {
            width: 50%;
            height: 100%;
            padding: 20px 24px 20px 20px;

            .ql-editor h4{
                text-align: left !important;
                font-weight: bold;
            }
            @media screen and (max-width: 1100px) {
               padding-top: 0;
            }

            h3 {
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 16px;
                color: #000;
                text-align: center;

                @media screen and (max-width: 1100px) {
                    font-size: 20px; 
                }
            }

            h5 {
                font-size: 14px;
                text-transform: uppercase;
                line-height: 20px;
                margin-bottom: 20px;
                color: #DC4405;
                text-align: center;
            }

            .method {
                font-size: 16px;
                line-height: 28px;
                text-align: center;
                // margin-bottom: 20px;
                padding-right: 20px;
                height: 350px;
                overflow-y: auto;

                a{
                color: #3F1BD0;
                }
                
                span{
                font-weight: normal !important;
                }

                @media screen and (max-width: 1100px) {
                    height: 350px;
                }

                @media screen and (max-width: 490px) {
                    height: 280px !important;
                }

            }

            .btn {
                margin-top: auto;
            }
        }


        .btn {
            width: 100%;
            margin-bottom: 8px;
        }

        .close {
            position: absolute;
            top: 18px;
            right: 25px;
            cursor: pointer;
            color: #ffffff;
            font-size: 24px;
            z-index: 99;
        }

        .close-modal-btn {
            background-color: #DC4405;
            color: #fff !important;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 8px;
            border-radius: 6px;
            right: 0px !important;
            top: 0px !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .view-product{
            position: absolute;
            bottom: 0px;
            left: 0px;
            cursor: pointer;
            color: #ffffff;
            font-size: 14px;  
            border-radius: 0;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            z-index: 99;
        }

        .other-images-btns{
            position: absolute;
            width: auto !important;
            display: flex;
            bottom: 0px;
            right: 0px;
            cursor: pointer;
            color: #ffffff;
            font-size: 14px;  
            border-radius: 0;
            z-index: 99;

            .btn{
            border-radius: 0px;
            border: 1px solid #FFF;
            }
        }

        .view-full-image{
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            color: #ffffff;
            font-size: 14px;  
            border-radius: 0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            z-index: 99;
        }
    }


    .methodology-modal-content {
        width: 1000px !important;
        height: 550px !important;
        flex-direction: row-reverse !important;
        justify-content: space-between !important;
        gap: 20px;
        padding: 0 !important;
        align-items: start;

        @media screen and (max-width: 1100px) {
            width: 95vw !important;
            height: 80vh !important;
            flex-direction: column !important;

            .img-container {
                width: 100%;
                height: 40%;

                img {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }

            .methods-text {
                width: 100%;
                height: 60%;
                overflow: hidden;

                p, h4 {
                    padding-left: 20px;
                }
            }
        }

        @media screen and (max-width: 790px) {
            height:85vh !important;
        }
    }



    .blue {
        .team-text {

            h3,
            h5 {
                color: #3F1BD0;
            }
        }
    }

    .gray {
        .team-text {
            h3 {
                color: #27282E;
            }

            h5 {
                color: #6C6A73;
            }
        }
    }

}


.color-405, .color-430{
    color: #0099FF
 }
 
 .color-488{
     color: #33C25E;
 }
 
 .color-532, .color-546{
     color: #FFC224;
 }
 
 .color-555, .color-568{
     color: #FF8D24
 }
 
 .color-594, .color-610,.color-633, .color-635{
     color: #FC5555
 }
 
 .color-647, .color-660{
     color: #D94949
 }
 
 .color-680, .color-700{
    color: #B23C3C
 }
 
 .color-750, .color-790{
 color: #993434
 }
 