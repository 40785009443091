.shape{
width: 160px;
height: 166px;
background-size: cover;
}

.shape-small{
    width: 80px;
    height: 80px;
    background-size: cover;
}

.shape-big{
    width: 224px;
    height: 224px;
    background-size: cover;
}