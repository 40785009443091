.bio-container {
    background-image: url(../../../public/media/teambg.png);
    background-size: cover;
    background-position: center;
}

.teams-wrapper {
    margin: 32px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 0 3rem;

@media screen and (max-width:1100px) {
    grid-template-columns: 1fr;
    padding: 0 1.2rem;  
}

@media screen and (min-width:2160px) {
   padding-left: 25rem;
   padding-right: 25rem;
}



    .team {
        display: flex;
        min-height: 450px;
        height: auto;
        gap: 40px;
        padding: 44px 70px;
        align-items: center;
        border-radius: 20px;
        background: linear-gradient(0deg, rgba(255, 242, 229, 0.48) 0%, rgba(255, 242, 229, 0.48) 100%), #FFF;

        @media screen and (max-width:1100px) {
            flex-direction: column;
            padding: 44px 1.2rem;  
        }
 


        img {
            width: 183px;
            height: 161px;
        }

        .team-text{
            h3{
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 16px;
                color: #DC4405;
            }
            h5{
                font-size: 14px;
                text-transform: uppercase;
                line-height: 20px;
                margin-bottom: 20px;
                color: #DC4405;
            }
            p{
                font-size: 16;
                line-height: 28px;
            }

            @media screen and (max-width:1100px) {
               text-align: center;

               p{
                text-align: justify;
               }
            }

        }
    }

    .blue{
        background: linear-gradient(0deg, rgba(241, 238, 252, 0.48) 0%, rgba(241, 238, 252, 0.48) 100%), #FFF;

        .team-text{
            h3,h5{
                color: #3F1BD0;
            }
        }
    }

    .blue-full{
        grid-column: span 2;

        @media screen and (max-width:1100px) {
            grid-column: span 1;
        }
    }

    .gray{
        background: linear-gradient(0deg, #F5F5F5 0%, #F5F5F5 100%), #FFF;
        .team-text{
            h3{
                color: #27282E;
            }
            h5{
                color:#6C6A73;
            }
        }
    }
}