.modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;


    .modal-content {
        position: relative;
        // min-height: 600px;
        // max-height: 85vh;
        width: 70%;
        display: flex;
        background: rgba(255, 255, 255, 1);
        border-radius: 20px;


        @media screen and (max-width: 1100px) {
            width: 95vw;
            flex-direction: column-reverse;
            max-height: 95vh;
            overflow-y: auto;
            .close {
                color: #27282e !important;
            }
        }

        .left-container {
            padding: 20px 32px 30px 32px; 
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 32px;
            overflow-y: auto;


            @media screen and (max-width: 1100px) {
                width: 100%;
                padding: 50px 22px;
            }


            .first-step {
                display: flex;
                flex-direction: column;
                gap: 32px;
            }

            .second-step,.third-step,.fourth-step,.fifth-step{
                ul{
                    margin-top: 12px;
                    display: flex;
                    flex-direction: column;
                    gap:5px;

                    li{
                        span{
                            color: var(--primary-color);
                            font-weight: 500;
                            cursor: pointer;
                        }
                    }
                    li::before {
                        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                        color: #6c6a73; /* Change the color */
                        font-weight: bold; /* If you want it to be bold */
                        display: inline-block; /* Needed to add space between the bullet and the text */
                        width: 1em; /* Also needed for space (tweak if needed) */
                        margin-left: -1em; /* Also needed for space (tweak if needed) */
                      }
                }

                .button-container{
                    margin-top: 40px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .btn{
                        width: 100%;
                    }
                }
            }

            h1 {
                margin-bottom: 12px;
            }

            p {
                color: #6c6a73;
                span {
                    color: var(--primary-color);
                    font-weight: 500;
                    cursor: pointer;
                }
            }

            .two-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media (max-width: 1000px) {
                    flex-direction: column;
                }
            }

            .three-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                }

                .phone-number-div {
                    width: 50%;
                    transform: translateY(-5px);

                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }
                }

                .two-column {
                    display: flex;
                    width: 50%;
                    justify-content: space-between;
                    gap: 20px;
                    margin-bottom: 0px !important;

                    @media (max-width: 1000px) {
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }

            select {
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                backdrop-filter: blur(19px);
                display: flex;
                height: 48px;
                padding: 10px 12px;
                outline: unset !important;
                // border: unset !important;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #28272e;
                width: 100%;

                @media (max-width: 768px) {
                    width: -webkit-fill-available;
                }

                @media (max-width: 1000px) {
                    width: -webkit-fill-available;
                }

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(../../assets/angle-down.png);
                background-repeat: no-repeat,
                repeat;
                background-position: right 0.7em top 50%,
                0 0;
                background-size: 0.65em auto,
                100%;
            }

            textarea {
                width: 100%;
                height: 90px;
                color: #7d8ca5;
                font-weight: 400;
                padding: 10px 12px;
                outline: unset !important;
                // overflow: hidden;
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                backdrop-filter: blur(19px);
                color: #28272E;
                text-overflow: ellipsis;
                // white-space: nowrap;
                resize: none;

                :focus-visible {
                    outline: unset !important;
                }

                &::placeholder {
                    color: #27282e;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    opacity: 0.5;
                }

                @media (max-width: 1000px) {
                    height: 140px !important;
                }
            }


            .select-option{
                padding: 5px 16px;
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                width: -webkit-fill-available;
            }

            .select-active{
            border: 1px solid #DC4405;
            background: #F1EEFC;
            }

            .containers {
                font-weight: 500;
                display: block;
                position: relative;
                padding-left: 35px;
                padding-top: 10px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 16px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: #0b161d;
            }

            /* Hide the browser's default radio button */
            .containers input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 100%;
            }

            /* Create a custom radio button */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 21px;
                width: 21px;
                background-color: transparent;
                border-radius: 50%;
                border: 2px solid rgb(215, 76, 16);
                transform: translateY(10px);
            }

            /* On mouse-over, add a grey background color */
            .containers:hover input~.checkmark {
                background-color: var(--primary-color);
            }

            /* When the radio button is checked, add a blue background */
            .containers input:checked~.checkmark {
                background-color: #fff;
            }

            /* Create the indicator (the dot/circle - hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the indicator (dot/circle) when checked */
            .containers input:checked~.checkmark:after {
                display: block;
            }

            /* Style the indicator (dot/circle) */
            .containers .checkmark:after {
                top: 3.5px;
                left: 3.5px;
                width: 10.5px;
                height: 10.5px;
                border-radius: 50%;
                background: var(--primary-color);
            }

            .containers:hover input:checked~.checkmark:after {
                top: 3.5px;
                left: 3.5px;
                width: 10.5px;
                height: 10.5px;
                border-radius: 50%;
                background: var(--primary-color);
            }


        }

        .right-container {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: url('../../../public/media/hero.webp');
            background-size: cover;
            // background-position: bottom;
            // mix-blend-mode: multiply;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

            @media screen and (max-width: 1100px) {
                display: none;
            }

            img {
                width: 245px;
                height: 52px;
                border-radius: 0;
            }
        }
    }
}


.checkbox-container {
    display: flex;
  }
  
  .checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #bbb;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  
  .checkbox-label {
    font-size: 16px;
  }
  
  .password-validations{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .error{
    border: 2px solid #dc1e00
  }