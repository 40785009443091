.small-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .small-modal-content {
        position: relative;
        height: auto;
        width: 380px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;

        @media screen and (max-width: 1100px) {
            width: 95vw;
            flex-direction: column;
        }

.img-container{
    width: 50%;
    height: 100%;

    img{
        width: 100%;
        height: 100%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        object-fit: cover;
    }
}
        .team-text{
            width: 50%;
            height: 100%;
            padding: 20px 0px 20px 0;
            h3{
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 16px;
                color: #DC4405;
            }
            h5{
                font-size: 14px;
                text-transform: uppercase;
                line-height: 20px;
                margin-bottom: 20px;
                color: #DC4405;
                text-align: center;
            }
            p{
                font-size: 16px;
                line-height: 28px;
                text-align: justify;
                margin-bottom: 20px;
                padding-right: 20px;
                height: 350px;
                overflow-y: auto;
                white-space: pre-line;

                &::-webkit-scrollbar-thumb{
                    background-color: #9a9a9a;
                }

                @media screen and (max-width:490px) {
                    height: 240px !important;
                }
            }

            .btn{
                margin-top: auto;
            }
        }

        
        .btn{
            width: 100%;
            margin-bottom: 8px;
        }

        .close{
            position: absolute;
            top: 18px;
            right: 25px;
            cursor: pointer;
        }
    }


    .team-modal-content{
        width: 70vw !important;
        height: 550px !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        gap: 20px;
        padding: 0 !important;
        align-items: start;

        img{
            border-top-left-radius: 20px !important;
            border-bottom-left-radius: 20px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            object-fit: cover !important;
        }
        

        @media screen and (max-width: 1100px) {
            width: 95vw !important;
            height: 90vh !important;
            flex-direction: column !important;

            .img-container{
                width: 100% !important;
                height: 60% !important;

                img{
                    border-top-left-radius: 20px !important;
                    border-top-right-radius: 20px !important;
                    border-bottom-left-radius: 20px !important;
                    border-bottom-right-radius: 20px !important;
                    height: auto !important;
                    width: 100% !important;
                }
            }
            .team-text{
                width: 100% !important;
                height: 100% !important;
                overflow: hidden;

                p{
                    padding-left: 20px;
                }
            }
        }


        @media screen and (min-width: 2160px) {
            width: 1000px !important;        
        }

    }
    


    .blue{
        .team-text{
            h3,h5{
                color: #3F1BD0;
            }
        }
    }

    .gray{
        .team-text{
            h3{
                color: #27282E;
            }
            h5{
                color:#6C6A73;
            }
        }
    }    

}