
.btn {
  display: block;
  background-color: var(--primary-color);
  color: var(--white);
  text-align: center;
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 12px;
}

.btn-transparent{
  background-color: transparent;
  color:var(--primary-color)
}

.icon {
  padding: 0.5rem;
  background-color: var(--light-grey);
  border-radius: 10px;
}

.logo {
  margin-right: 1.5rem;
}

/* #nav-menu {
  border-bottom: var(--border);
} */

.nav-container {
  position: fixed;
  z-index: 99;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  height: 75px;
  padding: 1rem 6rem;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(33px);
  --webkit-backdrop-filter: blur(33px);
}

@media screen and (min-width: 2160px) {
  .nav-container{
    padding-left:  25rem !important;
    padding-right: 25rem !important;
  }
}

@media screen and (min-width: 1240px) and (max-width: 1300px) {
  .nav-container {
    padding: 1rem 2rem;
  }
}

.menu {
  position: relative;
}

.menu-bar li:first-child .dropdown {
  flex-direction: initial;
  min-width: 480px;
}

.menu-bar li:first-child ul:nth-child(1) {
  border-right: var(--border);
}

/* .menu-bar li:nth-child(n + 2) ul:nth-child(1) {
  border-bottom: var(--border);
} */

.menu-bar .dropdown-link-title {
  font-weight: 600;
}

.menu-bar .nav-link {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.6px;
  padding: 0.3rem;
  min-width: 60px;
  margin: 0 0.6rem;
  /* color: var(--white); */
  color:#27282e;
}

.menu-bar .active{
  color: var(--primary-color) !important
}

.menu-bar .nav-link:hover,
.dropdown-link:hover {
  color: var(--primary-color);
}

.nav-start,
.nav-end,
.menu-bar,
.right-container,
.right-container .search {
  display: flex;
  align-items: center;
}

.dropdown {
  display: flex;
  flex-direction: column;
  min-width: 230px;
  border-radius: 12px;
  background: rgba(252, 252, 252, 1);
  backdrop-filter: blur(19px);
  position: absolute;
  top: 66px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.97) translateX(-5px);
  transition: 0.1s ease-in-out;
  box-shadow: var(--shadow);
  
}

.dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateX(5px);
}

.dropdown ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.2rem;
  font-size: 0.95rem;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.15rem;
}

.dropdown-link {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-radius: 7px;
  transition: 0.1s ease-in-out;
  font-size: 16px;
}

.dropdown-link p {
  font-size: 0.8rem;
  color: var(--medium-grey);
}

.right-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.right-container .search {
  position: relative;
}

.avatar{
  display: flex;
  align-items: center;
  gap: 10px;
}


@media screen and (max-width: 1240px) {
.avatar{
  justify-content: start !important;
}

.nav-end > .right-container > .avatar{
  display: none !important;
}

.nav-end > .right-container > .dropdown{
  display: none !important;
}
  
}

.custom-profile{
  background: linear-gradient(227.46deg, #DC4405 14.94%, #FF8D24 85.6%);
  backdrop-filter: blur(19px);
  border-radius: 12px;
  width: 48px;
  height: 48px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  }

.avatar-img{
  width: 48px !important;
  height: 48px !important;
  border-radius: 12px !important;
  backdrop-filter: blur(19px);
  object-fit: cover;
  border: 1px solid #F1EEFC;
}

.avatar-name{
  font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
color:#1E1E1E;
}

/* .right-container img {
  border-radius: 50%;
} */

.search input {
  background-color: var(--ash);
  border: none;
  border-radius: 6px;
  padding: 0.7rem;
  padding-left: 2.4rem;
  font-size: 16px;
  width: 100%;
  border: var(--border);
}

.search .bx-search {
  position: absolute;
  left: 10px;
  top: 50%;
  font-size: 1.3rem;
  transform: translateY(-50%);
  opacity: 0.6;
}

#hamburger {
  display: none;
  padding: 0.1rem;
  margin-left: 1rem;
  font-size: 1.9rem;
}

.auth-container{
  display: none;
}

@media (max-width: 1240px) {
  #hamburger {
    display: block;
  }

  .auth-container{
    display: block;
  }

  .auth-container > .btn{
    width: 100%;
  }

  .nav-container {
    padding: 1.2rem;
  }

  .menu {
    display: none;
    position: absolute;
    top: 78px;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background: rgba(252, 252, 252, 1);
  }

  .menu-bar li:first-child ul:nth-child(1) {
    border-right: none;
    border-bottom: var(--border);
  }

  .dropdown {
    display: none;
    min-width: 100%;
    border: none !important;
    border-radius: 5px;
    position: static;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    transform: none;
    box-shadow: none;
  }

  .menu.show,
  .dropdown.active {
    display: block;
  }


  .dropdown ul {
    padding-left: 0.3rem;
  }

  .menu-bar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
    padding: 1rem;
  }

  .menu-bar .nav-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
    color: #27282e;
  }

  .menu-bar li:first-child .dropdown {
    min-width: 100%;
  }

  .menu-bar > li:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom: var(--border);
  }
}

@media (max-width: 600px) {
  .right-container {
    display: none;
  }
}


.nav-white .menu-bar .nav-link {
  color: #27282e;
}


.cart-btn{
  position: relative;
}


.cart-btn > span{
  position: absolute;
  width: 18px;
  height: 18px;
border-radius: 4px;
background:  #DC4405;
padding: 2px 5px;
display: flex;
align-items: center;
justify-content: center;
font-size: 10px;
color: var(--white);
font-weight: 500;
top: -6px;
right: -6px;
}