.protein-dropdown {

    .clear-dropdown {
        background-color: #FFF2E5;
        color: var(--primary-color);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        border-radius: 6px;
        position: absolute;
        right: 5px;
        top: -60%;
        cursor: pointer;
        z-index: 9;
    }

    .selected-protein {
        border-radius: 12px;
        border: 1px solid #F1EEFC;
        background: #FCFCFC;
        backdrop-filter: blur(19px);
        display: flex;
        height: 48px;
        padding: 12px 16px;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        align-self: stretch;
        color: #28272E;
        white-space: nowrap;
        outline: unset !important;
        width: 300px;
        cursor: pointer;

        .protein-chip {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            background: var(--primary-color);
            backdrop-filter: blur(19px);
            color: #FCFCFC;
            width: max-content;
            padding: 5px 12px;

        }

    }


    .dropdown-container {
        position: absolute;
        padding: 12px 16px;
        height: max-content;
        max-height: 325px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border: 1px solid #F1EEFC;
        background: #FCFCFC;
        backdrop-filter: blur(19px);
        border-radius: 12px;
        width: 100%;
        z-index: 9;
        top: 105%;
        align-items: flex-start;

        .dropdown-item {
            color: #28272E;
            cursor: pointer;
            margin-left: 10px;

            &:hover {
                color: var(--primary-color);
                font-weight: 500;
            }
        }


    }

}