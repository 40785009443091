.footer {
    padding: 60px 6rem 60px 6rem;
    background-color: var(--primary-color);

    @media screen and (max-width: 1100px) {
        padding: 60px 3rem 30px 3rem;
    }
    @media screen and (min-width: 2160px) {
        padding: 60px 25rem 60px 25rem;
      }
}

.footer1{
    padding: 60px 6rem 60px 6rem;
    background-color: #d74c10ef;

    @media screen and (max-width: 1100px) {
        padding: 60px 3rem 60px 3rem;
    }

    @media screen and (min-width: 2160px) {
        padding: 60px 25rem 60px 25rem;
      }
}

.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 100px;

    @media screen and (max-width: 1220px) {
        justify-content: space-between;
        flex-wrap: wrap;
        display: flex;
        gap: 30px;
        
    }

@media screen and (max-width: 800px) {
    .links:nth-child(even),.links1:nth-child(even){
        order: 3;
    }
    
    .links:nth-child(3), .links:nth-child(4), .links1:nth-child(3), .links1:nth-child(4){
        justify-items: flex-end;
        text-align: right;
    }   
}

@media screen and (max-width:900px) {
    .links1:nth-child(even){
        order: 3;
    }
    .links1:nth-child(3), .links1:nth-child(4){
        justify-items: flex-end;
        text-align: right;
    }   

}


    @media screen and (max-width:500px) {
        .links:nth-child(3), .links:nth-child(4), .links1:nth-child(3), .links1:nth-child(4){
            text-align: left;
        }

        .links:nth-child(even),.links1:nth-child(even){
            order: 0;
        }
    }
    

}

.companyInfo {
    width: 330px;

    .logo img {
        width: 200px;
    }

    .text {
        margin: 26px 0 26px 0;
        color: #fcfcfc;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    h4{
        color: #fcfcfc;
        margin-bottom: 16px;
        font-size: 16px;
    }

.storelinks{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 120px;  
    img{
        width: 100%;
    }
}
}

.links,.links1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 220px;
    
h3{
    color: #FFF2E5;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    font-family: Inter;
}

h4{
    font-size: 16px;
    color: #fcfcfc;
    margin-bottom: 16px;
}

p{
color: #fcfcfc;
margin-top: 0;
}

    a {
        font-size: 16px;
        color: #fcfcfc;
    }
}


.links1{
    gap: 5px;
    width: auto;
}


    .icons {
        display: flex;
        gap: 25px;
        align-items: center;

    }
    