.zeiss-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 110px 3rem 60px 3rem;

    img {
        width: 84px;
    }
}



.azure-container {
    background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%);
    display: flex;
    justify-content: space-between;
    gap: 160px;
    width: 98vw;
    margin: 0 auto;
    border-radius: 20px;
    padding: 100px 5rem;

    @media screen and (max-width:1100px) {
        padding: 100px 1.2rem;
        flex-direction: column;

        .right-container {
            display: block;

        }
    }
}

.reverse{
    flex-direction: row-reverse;
    @media screen and (max-width:1100px) {
        padding: 100px 1.2rem;
        flex-direction: column;

        .right-container {
            display: block;

        }
    }
}


.left-container {
    .solutions-text {
        h2 {
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 12px;

            span {
                background: linear-gradient(227.46deg, #DC4405 14.94%, #FF8D24 85.6%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
            }
        }


        h3 {
            font-size: 36px;
            font-weight: 800;

            span {
                color: var(--primary-color)
            }
        }

        p {
            font-size: 20px;
            margin-top: 16px;
            line-height: 24px;
            width: 90%;

            span {
                color: var(--primary-color)
            }
        }

    }
}

.right-container {
    img {
        width: 420px;
        height: 428px;
        border-radius: 0;

        @media screen and (max-width:1100px) {
            width: 100%;
        }
    }

}