.small-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .small-modal-content {
        position: relative;
        height: auto;
        width: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 20px;

        @media screen and (max-width: 1100px) {
            width: 95vw;
            flex-direction: column;
        }

        h2 {
            margin-top: 12px;
            margin-bottom: 12px;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px
        }

        h5{
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: #1e1e1e;
        }

        p{
            text-align: left;
            font-size: 16px;
            line-height: 24px;
            color: #1e1e1e;
            // margin-bottom: 20px;

            span{
                font-weight: 700;
            }
        }

        .btn{
            width: 100%;
        }
    }

    .large-modal-content{
        width: 630px !important;

        @media screen and (max-width: 1100px) {
            width: 95vw !important;      
            
            
        .mobile-overflow{        
            overflow-y: auto;
            max-height: 450px;
        }
        }

        p{
            span{
                color: var(--primary-color);
            }

            a{
                color: #007CCF;
            }
        }
    }
    
}