.small-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .small-modal-content {
        position: relative;
        height: auto;
        width: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;

        @media screen and (max-width: 1100px) {
            width: 95vw !important;
            flex-direction: column;
        }

        h2 {
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            color:#1e1e1e;

            @media screen and (max-width: 490px) {
              font-size: 18px !important;
            }
        }   

        h4{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
        }

        p{
            text-align: center;
        }

        .btn{
            width: 100%;
            margin-bottom: 8px;
        }

        .close, .back{
            position: absolute;
            top: 32px;
            right: 32px;
            cursor: pointer;
            color: #1e1e1e;

            @media screen and (max-width: 1100px) {
                top:18px;
                right: 25px;
            }
        }

        .back{
            top:0;
            left:0;
            right: unset;
            color:var(--primary-color);
        }


.pathways-wrapper{
    display: flex;
    gap:32px;
    justify-content: center;
    margin-top: 36px;

    @media screen and (max-width:1100px) {
        flex-direction: column;
    }


    .pathway{
        position:relative;
        border-radius: 20px;
        background-size: 30%;
        background-repeat: no-repeat;
        background-position: center;
        background: #FFF;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
        width: 100%;
        height: 366px;
        
        &:nth-child(4), &:nth-child(5){
            width: 592px;
        
            @media screen and (max-width:1100px) {
                width: 100%;
            }
        }

        @media screen and (max-width:490px) {
            height: 250px;
        }
        
                
        .text-content{
        position: relative;
        border-radius: 0px 0px 12px 12px;
        background: rgba(91, 91, 91, 0.76);
        backdrop-filter: blur(18px);
        width: 100%;
        height: 96px;
        text-align: center;
        padding: 20px;
        display: flex;
        align-items: center;
        
        
        h4{
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; 
        color: #fcfcfc;
        }
        
        p{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #fcfcfc;
        }
        
        }
        
        .img-container{
            height: 270px;
            width: 100%;
        
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media screen and (max-width:490px) {
                height: 160px;
            }
        }
        
        
        }

}



    }

    .pathway-modal{
        @media screen and (max-width:1100px) {
            width: 450px !important
        }

        @media screen and (max-width:500px) {
            width: 95vw !important;
        }
    }
}