@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@400;500;700;800&family=Instrument+Sans:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}


:root {
  --text: #28272E;
  --dark-grey: #1e1e1e;
  --medium-grey: #636363;
  --light-grey: #eeeeee;
  --ash: #f4f4f4;
  --primary-color: #DC4405;
  --white: white;
  --border: 1px solid var(--light-grey);
  --shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


body {
  font-family: inherit;
  background-color: var(--white);
  color: var(--dark-grey);
  letter-spacing: -0.4px;
  scroll-behavior: smooth;
}


ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--text);
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
}

.transparent{
  background: transparent !important;
  color: #DC1E00 !important;
}

.container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 1rem 6rem;
}

.btn{
  height: 48px !important;

}

.btn:hover{
  background-color: #C03A00;
}

.btn-transparent:hover{
  background-color: transparent;
}


@media (max-width: 1100px) {
.container{
  padding: 1.2rem;
}
}

@media screen and (min-width: 2160px) {
  .container {
    padding-left:  25rem !important;
    padding-right: 25rem !important;
  }
}

.react-tooltip {
  background: #fff;
  border-radius: 12px !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.23);

}   

.header{
  display: flex;
  justify-content: space-between;
}


*::-webkit-scrollbar{
  width: 0.2rem;
}

*::-webkit-scrollbar:horizontal{
  height: 0.2rem;
}

*::-webkit-scrollbar-thumb{
  background-color: var(--primary-color);
  border-radius: 12px;
}

textarea::-webkit-scrollbar{
  width: 0.2rem;
}

textarea::-webkit-scrollbar:horizontal{
  height: 0.2rem;
}

textarea::-webkit-scrollbar-thumb{
  background-color: var(--primary-color);
  border-radius: 12px;
}

.rs-loader-backdrop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
}

.rs-loader-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.rs-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rs-loader-lg .rs-loader-spin {
  width: 64px;
  height: 64px;
  position: relative;
}

.rs-loader-spin::before, .rs-loader-spin::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

.rs-loader-spin::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
}

.rs-loader-spin::after {
  animation-duration: 0.6s;
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  animation: loaderSpin 0.6s infinite linear;
}

@keyframes loaderSpin {
  from {
      transform: rotate(0);
  }
  to {
      transform: rotate(360deg);
  }
}

.no-orders{
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:20px;


  span{
    width: 120px;
    height: 120px;
    font-size: 35px;
    align-items: center;
background: #fff2e5;
border-radius: 12px;
color: var(--primary-color);
display: flex;
justify-content: center;
}
p{
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px;
text-align: center;
}

}

@media screen and (max-width:1100px) {
    .no-orders{
    height: 100vh;
    }
}

.btn:disabled{
  opacity: 0.7;
  cursor: default;
}

.btn:disabled:hover{
  background-color: #DC4405;
}

@media print {
  html, body {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    overflow: hidden;
  }
}

i{
  font-weight: 300 !important;
}

.f900{
  font-weight: 900 !important;
}

.exclusive-container{
  padding: 12px 10px;
  background: #FFF2E5;
  border-radius: 12px;
  color: #DC4405;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
}