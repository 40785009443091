.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        color: var(--primary-color);
        word-break: keep-all;
    }

    .icons-container {
        display: flex;
        gap: 20px;
        align-items: center;
        color: #9a9a9a;
        font-size: 20px;
        width: 25px;
        height: 25px;

        i {
            cursor: pointer;
        }
    }

}


.dye-btn {
    display: block;
    background-color: #EDEDED;
    color: #000;
    text-align: center;
    padding: 12px 10px;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    border-radius: 12px;
    border: 1px solid #F1EEFC;
    background: #FCFCFC;
    backdrop-filter: blur(19px);
}


.tab-container,
.items-container {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EDEDED;
    border-radius: 12px;

    button {
        display: block;
        background-color: #EDEDED;
        color: #000;
        text-align: center;
        padding: 12px 10px;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: rgba(255, 141, 36, 0.28);
        }

        @media screen and (max-width:450px) {
            height: max-content;
        }
    }

    .tab-active {
        background-color: #FFF2E5;
        color: var(--primary-color);
        border-radius: 12px;
    }

}

.tab-container {
    @media screen and (max-width:450px) {
        height: max-content;
    }
}

.items-container {
    flex-direction: column;
    gap: 10px;
    height: max-content;
    background: transparent;

    button {
        width: 100%;
        border-radius: 12px;
        border: 1px solid #F1EEFC;
        opacity: 0.6;
        background: #FCFCFC;

        &:hover {
            background-color: #C03A00;
            color: #F1EEFC;
        }
    }

    .tab-active {
        background-color: var(--primary-color);
        color: #FFF;
        opacity: 1;
    }
}


.item-details-container {
    border-radius: 12px;
    background: rgba(237, 237, 237, 0.40);
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    h4 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .external_link {
        a{
            background-color: #4C47C4;
            padding: 2px 10px;
            color: #fff;
            border-radius: 5px;
            text-decoration: none;
            }
    }

    .item-detail-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    span {
        display: flex;
        height: 40px;
        padding: 12px 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        background: #DC4405;
        color: #FCFCFC;
        font-weight: 500;
        font-size: 16px;
    }

    .coa {
        height: 40px !important;
    }


    .item-content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left-container {
            display: flex;
            flex-direction: column;
            gap: 8px;

            h4 {
                color: #6c6a73;
                font-weight: 400;
            }
        }

        .right-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: flex-end;
            text-align: right;
            align-items: flex-end;

            .application-container {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                justify-content: end;

                span {
                    background: linear-gradient(0deg, rgba(255, 141, 36, 0.16) 0%, rgba(255, 141, 36, 0.16) 100%), #FFF;
                    color: var(--primary-color);
                    cursor: pointer;
                }
            }

        }
    }
}

.references-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

        a{
        color: #DC4405;
        word-wrap: break-word;

        &:hover{
            color: #C03A00;
        }
    }
}

.item-image-container {
    position: relative;

    .images-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 5px;

        .image {
            display: flex;
            flex-direction: column;
            gap: 8px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
            }

            h6 {
                font-size: 12px;
                font-weight: 400;
                color: #6c6a73;
                text-align: center;
            }

        }

    }


    .buttons-container {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 32px;
            height: 32px;
            border-radius: 8px;
            background: #FFF2E5;
            color: #DC4405;

            &:hover {
                background-color: rgba(255, 141, 36, 0.28);
            }
        }

        .dots {
            display: flex;
            gap: 5px;
            align-items: center;

            span {
                width: 8px;
                height: 6px;
                border-radius: 12px;
                background-color: #9a9a9a;
            }

            .active-dot {
                background-color: var(--primary-color);
                width: 16px;
            }
        }


    }


}


.add-cart-container {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: auto;

    @media screen and (max-width:1100px) {
        flex-direction: column;

        .btn {
            width: 100% !important;
            text-align: center;
            justify-content: center;
            height: 48px;
        }
    }

    .add-btn {
        width: 35%;
        display: flex;
        gap: 10px;
        align-items: center;
        color: var(--primary-color);
        background: #FFF2E5;

        &:hover {
            background-color: rgba(255, 141, 36, 0.28);
        }
    }

    .buy-btn {
        width: 65%;
    }


}

.cross_reactivity {
    margin-top: 5px;
    font-weight: 500 !important;
}