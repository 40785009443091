.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        color: var(--primary-color);
        word-break: keep-all;
    }

    .icons-container {
        display: flex;
        gap: 20px;
        align-items: center;
        color: #9a9a9a;
        font-size: 20px;
        width: 25px;
        height: 25px;

        i {
            cursor: pointer;
        }
    }

}


.function-text {
    color: #1e1e1e;
    font-size: 17px;
    font-weight: 400;
    display: grid;
    gap: 8px;

    span {
        color: var(--primary-color);
    }

    p {
        overflow: hidden;
    }

    ul {
        list-style-type: circle;
        display: grid;
        gap: 8px;
    }

    ol {
        list-style-type: decimal;
        display: grid;
        gap: 8px;
        margin-left: 20px;
    }
}

.mobile-overflow {
    overflow-y: auto;
    min-height: 200px;
}

.watermark {
    background: url(../../../../public/media/watermark.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.references {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    /* align-items: center; */

    h4 {
        color: #3f1bd0;
    }

    .references-list {
        display: flex;
        gap: 8px;

        a {
            font-size: 16px;
            font-weight: 400;
            color: var(--primary-color)
        }
    }


}