article {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 360px;
    border-radius: 16px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.26);
    position: relative;
    transform: translate3d(0, 0, 0);

    @media screen and (max-width:490px) {
        width: 100%;
    }

    &:hover{
        .cart-hover{
            opacity: 1;
        }
    }

    .price {
        position: absolute;
        padding: 12px;
        top:5px;
        left: 5px;
        border-radius: 58px;
        background: rgba(252, 252, 252, 1);
        backdrop-filter: blur(18px);
        color: #1e1e1e;
    }

    .cart-hover{
        opacity: 0;
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
        transition: 0.5s all ease;
    }

    //  overflow: hidden; 
    .img-card {
        width: 100%;
        height: 260px;
        background-color: #EDEDED;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        display: flex;

        @media screen and (max-width:490px) {
            width: 100%;
        }

        img{
           max-width: 100%;
            max-height: 100%;
            margin: auto;
            display: block;
            // background-size: cover;
            // border-top-left-radius: 16px;
            // border-top-right-radius: 16px;
        }
    }

    .info-container {
        height: 90px;
        padding: 16px 16px;

        .top-part {
            display: flex;
            align-items: center;
            height: 100%;

            h5 {
                font-size: 16px;
                font-weight: 400;
                color: #28272e;
                display: flex;
                gap: 5px;
                align-items: center;
            }

            h4 {
                font-size: 14px;
                white-space: pre-line;
            }
        }
    }
}