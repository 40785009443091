.modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .signin-modal-content {
        position: relative;
        height: max-content;
        width: 50%;
        display: flex;
        background: rgba(255, 255, 255, 1);
        border-radius: 20px;



        @media screen and (max-width: 1100px) {
            width: 95vw;
            flex-direction: column-reverse;    

    .close{
        color:#27282e !important;
    }
        }

        .left-container{
            padding: 32px;
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 32px;


        @media screen and (max-width: 1100px) {
            width: 100%; 
        }


            h1{
                margin-bottom: 12px;
            }

            p{
                color: #6c6a73;
                span{
                    color: var(--primary-color);
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }

        .right-container{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: url('../../../public/media/hero.webp');
            background-size: cover;
            // background-position: bottom;
            // mix-blend-mode: multiply;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        @media screen and (max-width: 1100px) {
            display: none;
        }

            img{
                width: 245px;
                height: 52px; 
                border-radius: 0;
            }
        }
    }
}