.hero-container {
    background: url(../../../public/media/headerbg1.png);
    // background-size: cover;
    height: 600px;
    background-position: 0 0;
    background-size: 100% 100%;
    margin-top: 75px;

    @media screen and (min-width: 2160px) {
        height: 800px;
        background-position: 0 0;
    }
}

.hero-text h1 {
    font-weight: 800;
    font-size: 56px;
    color: var(--white);
    margin-bottom: 24px;
}

.hero-text h1>span {
    background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}


.hero-text p {
    font-weight: 400;
    color: var(--white);
    width: 65%;
}


.technologies-container {
    padding: 100px 6rem;
    flex-direction: column;
    gap: 38px;
    display: inline-flex;
    align-items: center;
}


.technologies-text {
    text-align: center;
}

.technologies-text>h1 {
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
}

.technologies-text h1>span {
    background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.technologies-text h1>.red-gradient {
    background: linear-gradient(227deg, #E71316 0%, #DC4405 14%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.technologies-text p {
    text-align: center;
    line-height: 24px;
    width: 95%;
    margin: 0 auto;
    font-weight: 400;
}

.technologies-text p>span {
    color: var(--primary-color);
    font-weight: 600;
}

.partners-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
    align-items: center;
    justify-content: center;
}

.partners-container>.partners {
    display: flex;
    width: 228px;
    height: 120px;
    padding: 16px 112px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background-color: #ffe9d5;

    // img{
    //     width: 100%;
    // }
}

.bench-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 136px;
    background-color: #F8F7FE;
    width: 98vw;
    height: max-content;
    margin: 20px auto 0 auto;
    border-radius: 20px;
    padding: 134px 5rem;

    .solutions-text>h2 {
        font-size: 40px;
        font-weight: 800;
        margin-bottom: 12px;
    }

    .solutions-text h2>span {
        background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .solutions-text>p {
        color: #1e1e1e;
        font-size: 16px;
        margin-top: 16px;
        line-height: 24px;

        span {
            font-weight: 700;
            color: var(--primary-color);
        }
    }

    @media screen and (max-width:1100px) {
        padding: 60px 16px;

        .right-container {
            display: none;
        }
    }

}



.solutions-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    background-color: #3F1BD0;
    width: 98vw;
    height: max-content;
    margin: 0 auto;
    border-radius: 20px;
    padding: 100px 5rem;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../public/media/teambg.png');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.1;
        z-index: 0;
    }

    @media screen and (max-width: 1376px) {
        height: max-content;
    }

    .left-container {
        height: 100%;
        position: relative;
        z-index: 1;

        .solutions-text {
            height: auto;

            @media screen and (max-width: 1100px) {
                height: max-content;
            }
        }
    }
}

.solutions-container>.left-container>.solutions-text>h2 {
    color: var(--white);
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 12px;
}

.solutions-container>.left-container>.solutions-text>h3 {
    color: var(--white);
    font-size: 36px;
    font-weight: 600;
}

.solutions-container>.left-container>.solutions-text>h4 {
    color: var(--white);
    font-size: 24px;
    font-weight: 700;
}

.solutions-container>.left-container>.solutions-text>p {
    color: #fcfcfc;
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
}

.solutions-container>.left-container>.solutions-text>p>span {
    font-weight: 700;
    color: white;
}


.solutions-container>.left-container>.buttons-container {
    margin-top: 70px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 90%;
}


.solutions-container>.left-container>.buttons-container>.slider-controls {
    display: flex;
    gap: 16px;
}


.solutions-container>.left-container>.buttons-container>.btn {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(18px);
    height: 48px;
    width: 141px;

}

.solutions-container>.right-container {
    width: 480px;
    height: 480px;

}


.higlighted-container {
    padding: 0px 3rem;
    flex-direction: column;

    .technologies-container {
        gap: 32px;

        .technologies-text {
            h1 {
                width: 80%;
                margin: 0 auto;
            }
        }
    }

    .swiper-container {
        width: 85vw;
        height: 380px;
        overflow: hidden;
        margin: 0 auto;
    }

}


.teams-container {
    background-image: url("../../../public/media/teambg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 100px;
}

@media (max-width: 1100px) {
    .hero-container {
        background-image: url("../../../public/media/headerbg1.png");
        background-size: cover;
        background-position: right bottom;
        height: 700px;
    }

    .hero-text {
        width: 100%;
    }

    .hero-text h1 {
        font-size: 48px;
        text-align: center;
    }

    .hero-text p {
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .technologies-container {
        padding: 100px 1.2rem;
    }

    .partners-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .partners-container>.partners {
        width: 170px;
        padding: 50px;
    }

    .partners-container>.partners>img {
        width: 90px;
    }

    .solutions-container {
        display: grid;
        grid-template-columns: 1fr;
        padding: 100px 1.2rem 30px 1.2rem;
        gap: 100px;
        justify-content: center;

        .left-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .solutions-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                h3 {
                    font-size: 25px;
                    grid-template-columns: 1fr !important;
                    place-items: center;
                }
            }
        }
    }

    .solutions-container>.right-container {
        display: none;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .solutions-container>.right-container>img {
        width: 300px;
        height: 300px;
    }

}

@media (max-width: 490px) {
    .hero-container {
        background-image: url('../../../public/media/headermobile.png');
        background-size: cover;
    }

    .hero-text h1 {
        text-align: center;
        font-size: 28px;
        font-weight: 800;
    }

    .hero-text p {
        width: 100%;
        text-align: center;
    }

    .higlighted-container {
        .technologies-container {
            .technologies-text {
                h1 {
                    font-size: 32px;
                    width: 100%;
                    margin: 0 0 32px;
                }
            }
        }
    }
}


@media (max-width:400px) {

    .partners-container {
        .partners {
            width: 150px;

            img {
                width: 100px;
            }
        }
    }
}



.contact-container {
    background: url("../../../public/media/contactBg1.png") no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: space-between;
    width: 98vw;
    gap: 32px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 80px 5rem;

    .proceed-btn {
        display: none;
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        padding: 100px 1.2rem;

        .left-container {
            width: 100% !important;
        }

        .right-container {
            display: block;
            width: 100% !important;
        }

    }

    @media screen and (max-width: 500px) {
        background-position: left bottom;

        .left-container>.solutions-text {
            h2 {
                opacity: 0.6;

                span {
                    background: transparent;
                    -webkit-text-fill-color: #fff;
                }
            }

            p {
                display: none;
            }

            .proceed-btn {
                display: block;
                background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 16px;
                font-weight: 600;
            }
        }

        .right-container {
            display: none;
        }

    }

    .left-container {
        width: 45%;

        .solutions-text {
            h2 {
                color: var(--white);
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 12px;

                span {
                    color: var(--primary-color)
                }
            }

            p {
                color: #fcfcfc;
                font-size: 16px;
                margin-top: 16px;
                line-height: 24px;
                width: 70%;
            }
        }
    }

    .right-container {
        width: 55%;

        .form-container {
            width: 100%;
            height: max-content;
            border-radius: 16px;
            background: rgba(252, 252, 252, 0.92);
            backdrop-filter: blur(18px);
            padding: 24px;

            @media (max-width: 1000px) {
                width: 100%;
                height: max-content;

                .btn {
                    float: none !important;
                }
            }

            .two-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media (max-width: 1000px) {
                    flex-direction: column;
                }
            }

            .three-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                }

                .phone-number-div {
                    width: 50%;
                    transform: translateY(-5px);

                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }
                }

                .two-column {
                    display: flex;
                    width: 50%;
                    justify-content: space-between;
                    gap: 20px;
                    margin-bottom: 0px !important;

                    @media (max-width: 1000px) {
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }

            select {
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                backdrop-filter: blur(19px);
                display: flex;
                height: 48px;
                padding: 10px 12px;
                outline: unset !important;
                border: unset !important;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #28272e;
                width: 100%;

                @media (max-width: 768px) {
                    width: -webkit-fill-available;
                }

                @media (max-width: 1000px) {
                    width: -webkit-fill-available;
                }

                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(../../assets/angle-down.png);
                background-repeat: no-repeat,
                repeat;
                background-position: right 0.7em top 50%,
                0 0;
                background-size: 0.65em auto,
                100%;
            }

            textarea {
                width: 100%;
                height: 90px;
                color: #7d8ca5;
                font-weight: 400;
                padding: 10px 12px;
                outline: unset !important;
                // overflow: hidden;
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                backdrop-filter: blur(19px);
                color: #28272E;
                text-overflow: ellipsis;
                // white-space: nowrap;
                resize: none;

                :focus-visible {
                    outline: unset !important;
                }

                &::placeholder {
                    color: #9a9a9a;
                    font-family: "Inter";
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    opacity: 1;
                }

                @media (max-width: 1000px) {
                    height: 140px !important;
                }
            }

        }
    }

}

.icon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #fff2e5;
    color: var(--primary-color);
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
    }

    @media screen and (max-width:768px) {
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 8px;
    }
}

.left {
    position: absolute;
    left: -7%;
    top: 25%;
    z-index: 10;

    @media screen and (max-width:768px) {
        top: -12%;
        left: 80%;
    }
}


.right {
    position: absolute;
    right: -7%;
    top: 25%;
    z-index: 1;

    @media screen and (max-width:768px) {
        top: -12%;
        right: 0;
    }
}


.slider-container {
    position: relative;

    @media screen and (max-width: 1100px) {
        margin-top: 20px;
    }
}


.articles-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 85vw !important;
    // margin: 0 auto;
    overflow: hidden;
    gap: 32px;
    padding-bottom: 10px;
    // margin-top: 36px;
    // padding-bottom: 120px;
    //  padding-right: 30px;
    //  padding-left: 30px;
    flex-wrap: nowrap;
    white-space: nowrap;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width:1100px) {
        justify-content: start;
    }

    @media screen and (min-width:1700px) {
        width: 75vw !important;
    }

}

.team-card {
    background: none;
    border: none;
    box-shadow: none;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .article-text {
        text-align: center;
        text-wrap: wrap;
        height: 76px;

        h5 {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 8px;
        }

        p {
            color: #3f1db0;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-transform: uppercase;
            white-space: pre-wrap;

        }
    }
}


.team-text>h1 {
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;

    span {
        cursor: pointer;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
            background: linear-gradient(227deg, #3F1BD0 14.94%, #DC4405 85.6%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.team-text h1>.team-active {
    background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.team-text h1>.team-bio-active {
    background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: auto;
    pointer-events: none;
}



// .wheel-container{
//     position: relative;
//     height: 100%;
//     width: 480px;

// @media screen and (max-width:1100px) {
//     width: 90%;
// }


// img{
//     position: absolute;
//     width: auto !important;
//     height: auto !important;
// }


// .red{
//     left: -136px;
//     top: 36px;
// }

// .active-red{
//     opacity: 0;
//     left: -130px;
//     top: 41px;
//     rotate: -3deg;
// }

// .blue{
//     top: -97px;
// }

// .active-blue{
//     opacity: 0;
//     top: -97px;
//     left:-13px;
// }


// .green{
//     right: -19px;
//     top: 45px;

//     @media screen and (max-width:1100px) {
//         right: -116px;        
//     }
// }

// .active-green{
//     opacity: 0;
//     right: -19px;
//     top: 32px;

//     @media screen and (max-width:1100px) {
//         right: -116px;        
//     }
// }


// .orange{
//     bottom: -46px;
// }

// .active-orange{
//     opacity: 0;
//     bottom: -60px;
// }

// .active{
//     z-index: 9;
//     opacity: 1;
//     transition: 0.2s opacity ease-in-out;
// }

// .wheel-logo{
//     position: absolute;
//     top: 45%;
//     left: 30%;
//     transform: translate(-30%, -45%);

//     @media screen and (max-width:1100px) {
//         top: 45%;
//         left: 45%;
//         transform: translate(-45%, -45%);     
//     }

// }

// }

.wheel-container {
    position: relative;
    height: 100%;
    width: 480px;

    @media screen and (max-width:1100px) {
        width: 90%;
    }


    img {
        width: auto !important;
        height: auto !important;
        position: absolute;
        cursor: pointer;
    }


    .red {
        left: -45px;
        top: 68px;
        z-index: 0;
    }

    .active-red {
        opacity: 0;
        left: -60px;
        top: 52px;
        rotate: 0deg;

    }

    .blue {
        top: 0px;
        z-index: 0;
    }

    .active-blue {
        opacity: 0;
        top: -18px;
        left: -24px;
    }


    .green {
        right: 94px;
        top: 37px;
        z-index: 0;

        @media screen and (max-width:1100px) {
            right: -44px;
        }
    }

    .active-green {
        opacity: 0;
        right: 76px;
        top: 18px;


        @media screen and (max-width:1100px) {
            right: -54px;
        }
    }


    .orange {
        bottom: 50px;
        left: 22px;
        rotate: -3deg;
        z-index: 0;
    }

    .active-orange {
        opacity: 0;
        bottom: 32px;
        left: 3px;
        rotate: -1deg;

    }

    .active {
        z-index: 9;
        opacity: 1;
        transition: 0.2s opacity ease-in-out;
    }

    .wheel-logo {
        position: absolute;
        top: 40%;
        left: 25%;
        transform: translate(-25%, -40%);

        @media screen and (max-width:1100px) {
            top: 45%;
            left: 45%;
            transform: translate(-45%, -45%);
        }

    }

}

.featured-container {
    background: rgba(0, 0, 0, 1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    top: 75px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 40px;
    color: #FCFCFC;
    z-index: 10;
    padding: 0 1rem;

    @media screen and (max-width:768px) {
        height: 60px;
        width: 100%;
    }

    p {
        font-size: 16px;

        span {
            background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    a {
        color: #FCFCFC;
        text-decoration: underline;
        text-decoration-color: #DC4405;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1.5px;
        background: linear-gradient(227deg, #DC4405 14.94%, #FF8D24 85.6%);
        background-clip: padding-box;
    }
}

.thermo-container {
    background: #fff;
    border-radius: 12px;
    color: #000;
    padding: 0;
    margin: 0;
    gap: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    h2 {
        font-size: 26px;
    }

    img {
        width: 150px;
    }
}