.reagents-container {
    padding-top: 130px;
    background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%);
}

.reagents-wrapper {
    margin: 100px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 0 3rem;

@media screen and (max-width:1100px) {
    grid-template-columns: 1fr;
    padding: 0 1.2rem;  
}


    .team {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 24px 32px;
        height: 324px;
        align-items: flex-start;
        justify-content: flex-end;
        border-radius: 20px;
        background: var(--primary-color);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom;
        cursor: pointer;

        @media screen and (max-width:1100px) {
            flex-direction: column;
            padding: 24px 1.2rem;  
            height: 212px;
        }
 
        h3{
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 16px;
            color: #fff;
        }

}
}