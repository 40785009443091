.small-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .small-function-modal-content {
        position: relative;
        height: auto;
        width: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 20px;

        @media screen and (max-width: 1100px) {
            width: 95vw;
            flex-direction: column;
        }

        h2 {
            margin-top: 12px;
            margin-bottom: 12px;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px
        }

        h5 {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #1e1e1e;
        }

        .btn {
            width: 100%;
        }
    }

    .large-function-modal-content {
        width: 50vw !important;

        .function-text {
            color: #1e1e1e;
            font-size: 17px;
            font-weight: 400;
            display: grid;
            gap: 8px;

            span {
                color: var(--primary-color);
            }

            p {
                overflow: hidden;
            }

            ul {
                list-style-type: circle;
                display: grid;
                gap: 8px;
            }

            ol {
                list-style-type: decimal;
                display: grid;
                gap: 8px;
                margin-left: 20px;
            }
        }

        .mobile-overflow {
            overflow-y: auto;
            max-height: 450px;
        }

        .references {
            display: flex;
            gap: 8px;
            margin-top: 20px;
            /* align-items: center; */


            .references-list {
                display: flex;
                gap: 8px;

                a {
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--primary-color)
                }
            }


        }


        @media screen and (max-width: 1100px) {
            width: 95vw !important;

        }
    }

}